* {
  margin: 0;
  padding: 0
}

html, body {
  overflow-x: hidden;
  background-color: black;
}

@font-face {
  font-family: robotoBlack;
  src: url(../public/fonts/Roboto-Black.ttf);
}

.robotoBlack {
  font-family: robotoBlack;
}

@font-face {
  font-family: robotoBlackItalic;
  src: url(../public/fonts/Roboto-BlackItalic.ttf);
}

.robotoBlackItalic {
  font-family: robotoBlackItalic;
}

@font-face {
  font-family: robotoBold;
  src: url(../public/fonts/Roboto-Bold.ttf);
}

.robotoBold {
  font-family: robotoBold;
}

@font-face {
  font-family: robotoBoldItalic;
  src: url(../public/fonts/Roboto-BoldItalic.ttf);
}

.robotoBoldItalic {
  font-family: robotoBoldItalic;
}

@font-face {
  font-family: roboto;
  src: url(../public/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: robotoMedium;
  src: url(../public/fonts/Roboto-Medium.ttf);
}

.robotoMedium {
  font-family: robotoMedium;
}

@font-face {
  font-family: robotoMediumItalic;
  src: url(../public/fonts/Roboto-MediumItalic.ttf);
}

.robotoMediumItalic {
  font-family: robotoMediumItalic
}

.roboto {
  font-family: roboto;
}

@font-face {
  font-family: robotoItalic;
  src: url(../public/fonts/Roboto-Italic.ttf);
}

.robotoItalic {
  font-family: robotoItalic;
}

@font-face {
  font-family: robotoLight;
  src: url(../public/fonts/Roboto-Light.ttf);
}

.robotoLight {
  font-family: robotoLight;
}

@font-face {
  font-family: robotoLightItalic;
  src: url(../public/fonts/Roboto-LightItalic.ttf);
}

.robotoLightItalic {
  font-family: robotoLightItalic;
}

@font-face {
  font-family: robotoThin;
  src: url(../public/fonts/Roboto-Thin.ttf);
}

.robotoThin {
  font-family: robotoThin;
}

@font-face {
  font-family: robotoThinItalic;
  src: url(../public/fonts/Roboto-ThinItalic.ttf);
}

.robotoThinItalic {
  font-family: robotoThinItalic;
}

@font-face {
  font-family: nothingYouCouldDo;
  src: url(../public/fonts/NothingYouCouldDo.ttf);
}

.nothingYouCouldDo {
  font-family: nothingYouCouldDo;
}

.videoWrapper {
  position: relative;
  height: 100%; 
  display: block;
  background-color: black;
}